import React, { useState, useEffect } from "react";
import {
  Box,
  Select,
  MenuItem,
  Button,
  Typography,
  CircularProgress,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import CityModal from "./CityModal"; // Import the modal component

const CityManager = () => {
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [loading, setLoading] = useState(false);

  const [modalOpen, setModalOpen] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [selectedCity, setSelectedCity] = useState(null);
  const [pageSize, setPageSize] = useState(10);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}country`, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      })
      .then((response) => setCountries(response.data.data || []))
      .catch((error) => console.error("Error fetching countries:", error));
  }, []);

  useEffect(() => {
    if (!selectedCountry) return;
    axios
      .get(`${process.env.REACT_APP_API_URL}state/${selectedCountry}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      })
      .then((response) => setStates(response.data.data || []))
      .catch((error) => console.error("Error fetching states:", error));
  }, [selectedCountry]);

  useEffect(() => {
    if (!selectedState) return;
    setLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}subcity/${selectedState}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      })
      .then((response) => setCities(response.data.data || []))
      .catch((error) => console.error("Error fetching cities:", error))
      .finally(() => setLoading(false));
  }, [selectedState]);

  const handleOpenModal = (city = null) => {
    if (city) {
      setEditMode(true);
      setSelectedCity(city);
    } else {
      setEditMode(false);
      setSelectedCity(null);
    }
    setModalOpen(true);
  };

  const columns = [
    {
      field: "id",
      headerName: "SNo.",
      width: 80,
      headerClassName: "custom-header",
    },
    {
      field: "city_name",
      headerName: "City Name",
      flex: 1,
      headerClassName: "custom-header",
    },
    {
      field: "city_code",
      headerName: "City Code",
      flex: 1,
      headerClassName: "custom-header",
    },
    {
      field: "actions",
      headerName: "Actions",
      headerClassName: "custom-header",
      width: 120,
      renderCell: ({ row }) => (
        <Button
          variant="contained"
          onClick={() => handleOpenModal(row)}
          sx={{ backgroundColor: "#4A51F8" }}
        >
          Edit
        </Button>
      ),
    },
  ];

  return (
    <Box
      sx={{ mt: 4, p: 3, bgcolor: "#f9f9f9", borderRadius: 3, boxShadow: 2 }}
    >
      <Typography
        variant="h5"
        sx={{ fontWeight: "bold", mb: 2, color: "#4A51F8" }}
      >
        Manage Cities
      </Typography>

      <Box display="flex" gap={2} mb={3}>
        <Select
          value={selectedCountry}
          onChange={(e) => setSelectedCountry(e.target.value)}
          displayEmpty
          sx={{ minWidth: 200, bgcolor: "white", borderRadius: 2 }}
        >
          <MenuItem value="">Select a Country</MenuItem>
          {countries.map((country) => (
            <MenuItem key={country.country_id} value={country.country_id}>
              {country.country_name}
            </MenuItem>
          ))}
        </Select>

        <Select
          value={selectedState}
          onChange={(e) => setSelectedState(e.target.value)}
          displayEmpty
          sx={{ minWidth: 200, bgcolor: "white", borderRadius: 2 }}
        >
          <MenuItem value="">Select a State</MenuItem>
          {states.map((state) => (
            <MenuItem key={state.state_id} value={state.state_id}>
              {state.state_name}
            </MenuItem>
          ))}
        </Select>

        <Button
          variant="contained"
          onClick={() => handleOpenModal()}
          sx={{ backgroundColor: "#4A51F8" }}
        >
          Add City
        </Button>
      </Box>

      {loading ? (
        <CircularProgress />
      ) : (
        <DataGrid
          rows={cities.map((city, i) => ({ ...city, id: i + 1 }))}
          columns={columns}
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 10,
              },
            },
          }}
          pageSizeOptions={[10]}
          sx={{
            "& .custom-header": {
              backgroundColor: "#4A51F8",
              color: "white",
            },
            "& .MuiDataGrid-columnHeaderTitle": { color: "white" },
            "& .MuiDataGrid-sortIcon, & .MuiDataGrid-menuIconButton": {
              color: "#fff",
            },
            "& .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-columnHeader:focus-within":
              { outline: "none" },
          }}
        />
      )}

      {/* City Modal */}
      <CityModal
        open={modalOpen}
        handleClose={() => setModalOpen(false)}
        editMode={editMode}
        selectedCity={selectedCity}
        selectState={selectedState}
        selectCountry={selectedCountry}
        fetchCities={() => {
          if (selectedState) {
            setLoading(true);
            axios
              .get(`${process.env.REACT_APP_API_URL}subcity/${selectedState}`, {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
              })
              .then((response) => setCities(response.data.data || []))
              .catch((error) => console.error("Error fetching cities:", error))
              .finally(() => setLoading(false));
          }
        }}
      />
    </Box>
  );
};

export default CityManager;
