import React from "react";
import Sidebar from "../components/Sidebar";
import NavbarComponent from "../components/Navbar";
import Uploaded from "../components/Uploaded";

const TicketUploadPage = () => {
  return (
    <>
      <div className="d-flex">
        <Sidebar />
        <div className="flex-grow-1">
          <Uploaded />
        </div>
      </div>
    </>
  );
};

export default TicketUploadPage;
