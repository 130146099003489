import React from "react";
import AgeRange from "../components/AgeRange";
import Sidebar from "../components/Sidebar";
import NavbarComponent from "../components/Navbar";
import Phone from "../components/Phone";

const PhonePage = () => {
  return (
    <>
      <div className="d-flex">
        <Sidebar />
        <div className="flex-grow-1">
          <Phone />
        </div>
      </div>
    </>
  );
};

export default PhonePage;
