import React from "react";
import Flight from "../components/Flight";
import Sidebar from "../components/Sidebar";
import NavbarComponent from "../components/Navbar";

const AirlinePage = () => {
  return (
    <>
      <div className="d-flex">
        <Sidebar />
        <div className="flex-grow-1">
          <Flight />
        </div>
      </div>
    </>
  );
};

export default AirlinePage;
