import React, { useEffect, useState } from "react";
import axios from "axios";
import { Box, Typography, CircularProgress, Button } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";

const User = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const [pageSize, setPageSize] = useState(10);

  useEffect(() => {
    setLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}user`, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      })
      .then((response) => setUsers(response.data))
      .catch((error) => console.error("Error fetching users:", error))
      .finally(() => setLoading(false));
  }, []);

  const handleChange = async (locked, user_id) => {
    if (
      window.confirm(
        `Are you sure you want to ${locked ? "unlock" : "lock"} this user?`
      )
    ) {
      try {
        await axios.post(
          `${process.env.REACT_APP_API_URL}user/${user_id}/locked`,
          { locked: locked === 1 ? 0 : 1 },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        setUsers(
          users.map((user) =>
            user.user_id === user_id ? { ...user, locked: !locked } : user
          )
        );
      } catch (error) {
        alert("User locked status change failed");
      }
    }
  };

  const columns = [
    { field: "sNo", headerName: "S.No", width: 80 },
    { field: "name", headerName: "Name", flex: 1 },
    {
      field: "phone_number",
      headerName: "Phone Number",
      flex: 1,
      valueGetter: (params) =>
        params?.row
          ? `${params.row.customer?.country_phone_code} ${params.row.customer?.phone_number}`
          : "",
    },
    {
      field: "locked",
      headerName: "Locked",
      flex: 1,
      valueGetter: (params) => (params?.row?.locked ? "Yes" : "No"),
    },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      renderCell: (params) => {
        if (!params.row) return null;
        return (
          <Button
            onClick={() => handleChange(params.row.locked, params.row.user_id)}
            color={params.row.locked ? "secondary" : "primary"}
          >
            {params.row.locked ? "Unlock" : "Lock"}
          </Button>
        );
      },
    },
  ];

  return (
    <Box
      sx={{ mt: 4, p: 3, bgcolor: "#f9f9f9", borderRadius: 3, boxShadow: 2 }}
    >
      <Typography
        variant="h5"
        sx={{ fontWeight: "bold", mb: 2, color: "#4A51F8" }}
      >
        Users
      </Typography>
      {loading ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height={200}
        >
          <CircularProgress />
        </Box>
      ) : (
        <DataGrid
          rows={users.map((row, index) => ({ ...row, sNo: index + 1 }))}
          columns={columns}
          getRowId={(row) => row.user_id}
          pageSize={itemsPerPage}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 10,
              },
            },
          }}
          pageSizeOptions={[10]}
          sx={{
            "& .MuiDataGrid-columnHeader": {
              backgroundColor: "#4A51F8",
              color: "white",
            },
            "& .MuiDataGrid-columnHeaderTitle": { color: "white" },
          }}
        />
      )}
    </Box>
  );
};

export default User;
