import React, { useEffect, useState } from "react";
import axios from "axios";
import { useDispatch } from "react-redux";
import {
  addConfirmationCode,
  removeConfirmationCode,
} from "../utils/ConfirmationSlice";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Typography,
  CircularProgress,
  Button,
  MenuItem,
  Select,
  FormControl,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";

const RecentRequest = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [requests, setRequests] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const [filter, setFilter] = useState("No Action");

  useEffect(() => {
    setLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}recent_request?status=${filter}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        setRequests(response?.data?.data || []);
      })
      .catch((error) => {
        console.error("Error fetching requests:", error);
        setRequests([]);
      })
      .finally(() => {
        setLoading(false);
      });

    dispatch(removeConfirmationCode());
  }, [filter, dispatch]);

  const handleChange = (confirmation_code) => {
    dispatch(addConfirmationCode(confirmation_code));
    navigate("/");
  };

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
  };

  const columns = [
    { field: "sNo", headerName: "S.No", width: 80 },
    {
      field: "confirmation_code",
      headerName: "Confirmation Code",
      flex: 1,
      renderCell: ({ row }) => (
        <Typography
          color="#4A51F8"
          sx={{ cursor: "pointer", pt: 2 }}
          onClick={() => handleChange(row.confirmation_code)}
        >
          {row.confirmation_code}
        </Typography>
      ),
    },
    {
      field: "start_date_of_travel",
      headerName: "Start Date of Travel",
      flex: 1,
      renderCell: ({ row }) =>
        new Date(row.start_date_of_travel).toLocaleDateString("en-GB"),
    },
    {
      field: "match_date_time",
      headerName: "Match Date & Time",
      flex: 1,
      renderCell: ({ row }) =>
        new Date(row.match_date_time).toLocaleString("en-US", {
          dateStyle: "medium",
          timeStyle: "medium",
        }),
    },
  ];

  return (
    <Box
      sx={{ mt: 4, p: 3, bgcolor: "#f9f9f9", borderRadius: 3, boxShadow: 2 }}
    >
      <Typography
        variant="h5"
        sx={{ fontWeight: "bold", mb: 2, color: "#4A51F8" }}
      >
        Recent Requests
      </Typography>

      <FormControl fullWidth sx={{ mb: 2 }}>
        <Select value={filter} onChange={handleFilterChange}>
          <MenuItem value="No Action">No Action</MenuItem>
          <MenuItem value="Received">Received</MenuItem>
          <MenuItem value="Sent">Sent</MenuItem>
          <MenuItem value="Approved">Approved</MenuItem>
          <MenuItem value="Declined">Declined</MenuItem>
        </Select>
      </FormControl>

      {loading ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height={200}
        >
          <CircularProgress />
        </Box>
      ) : requests.length === 0 ? (
        <Typography textAlign="center" mt={3}>
          No Recent Requests Found.
        </Typography>
      ) : (
        <DataGrid
          rows={requests.map((row, index) => ({ ...row, sNo: index + 1 }))}
          columns={columns}
          getRowId={(row) => row.confirmation_code}
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          initialState={{ pagination: { paginationModel: { pageSize: 10 } } }}
          pageSizeOptions={[10]}
          sx={{
            "& .MuiDataGrid-columnHeader": {
              backgroundColor: "#4A51F8",
              color: "white",
            },
            "& .MuiDataGrid-columnHeaderTitle": { color: "white" },
          }}
        />
      )}
    </Box>
  );
};

export default RecentRequest;
