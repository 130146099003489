import React, { useEffect, useState } from "react";
import axios from "axios";
import { Box, Typography, CircularProgress, Button } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";

const EnableFlight = () => {
  const [flights, setFlights] = useState([]);
  const [loading, setLoading] = useState(false);
  const [msg, setMsg] = useState("");

  useEffect(() => {
    setLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}enableflightInformation`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        if (response?.data?.message === "No inactive flights found.") {
          setMsg(response?.data?.message);
        } else {
          setFlights(response?.data?.data || []);
        }
      })
      .catch((error) => console.error("Error fetching flights:", error))
      .finally(() => setLoading(false));
  }, []);

  const handleChange = async (locked, id) => {
    if (window.confirm("Are you sure you want to activate this flight?")) {
      try {
        await axios.post(
          `${process.env.REACT_APP_API_URL}enableflightInformation/${id}`,
          { active: locked === 1 ? 0 : 1 },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        alert("Successfully activated the flight.");
        setFlights(
          flights.map((flight) =>
            flight.flight_id === id ? { ...flight, active: 1 } : flight
          )
        );
      } catch (error) {
        alert("Unable to activate the flight.");
      }
    }
  };

  const handleDelete = async (id) => {
    if (window.confirm("Are you sure you want to delete this flight?")) {
      try {
        await axios.delete(
          `${process.env.REACT_APP_API_URL}delete-flight/${id}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        alert("Successfully deleted the flight.");
        setFlights(flights.filter((flight) => flight.flight_id !== id));
      } catch (error) {
        alert("Unable to delete the flight.");
      }
    }
  };

  const columns = [
    { field: "sNo", headerName: "S.No", width: 80 },
    { field: "flight_code", headerName: "Flight Code", flex: 1 },
    { field: "airline_name", headerName: "Airline Name", flex: 1 },
    { field: "from_airport_name", headerName: "From Airport", flex: 1 },
    { field: "to_airport_name", headerName: "To Airport", flex: 1 },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      renderCell: ({ row }) =>
        row.active === 0 ? (
          <Button
            onClick={() => handleChange(row.active, row.flight_id)}
            color="primary"
          >
            Activate
          </Button>
        ) : null,
    },
    {
      field: "delete",
      headerName: "Delete",
      flex: 1,
      renderCell: ({ row }) => (
        <Button onClick={() => handleDelete(row.flight_id)} color="error">
          Delete
        </Button>
      ),
    },
  ];

  return (
    <Box
      sx={{ mt: 4, p: 3, bgcolor: "#f9f9f9", borderRadius: 3, boxShadow: 2 }}
    >
      <Typography
        variant="h5"
        sx={{ fontWeight: "bold", mb: 2, color: "#4A51F8" }}
      >
        Review Flights
      </Typography>
      {loading ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height={200}
        >
          <CircularProgress />
        </Box>
      ) : msg ? (
        <Typography textAlign="center" mt={3}>
          {msg}
        </Typography>
      ) : (
        <DataGrid
          rows={flights.map((row, index) => ({ ...row, sNo: index + 1 }))}
          columns={columns}
          getRowId={(row) => row.flight_id}
          pageSize={10}
          sx={{
            "& .MuiDataGrid-columnHeader": {
              backgroundColor: "#4A51F8",
              color: "white",
            },
            "& .MuiDataGrid-columnHeaderTitle": { color: "white" },
          }}
        />
      )}
    </Box>
  );
};

export default EnableFlight;
