import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import Sidebar from "./Sidebar";

const CaseMessages = () => {
  const { cases_id } = useParams();
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [loading, setLoading] = useState(false);

  // Fetch messages when the component loads
  useEffect(() => {
    setLoading(true);
    axios
      .get(`http://localhost:5000/admincrm/cases/${cases_id}/messages`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        setMessages(response.data.data);
      })
      .catch((error) => {
        console.error("Error fetching messages:", error);
      })
      .finally(() => setLoading(false));
  }, [cases_id]);

  // Handle sending a new message
  const handleSendMessage = () => {
    if (!newMessage.trim()) return;

    axios
      .post(
        `http://localhost:5000/admincrm/cases/messages`,
        { cases_id, message: newMessage },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        setMessages([
          ...messages,
          {
            from: "ADMIN",
            message: newMessage,
            created_at: new Date().toISOString(),
          },
        ]);
        setNewMessage("");
      })
      .catch((error) => {
        console.error("Error sending message:", error);
      });
  };

  return (
    <div className="container mt-4">
      <div className="card shadow-sm">
        <div className="card-header bg-primary text-white text-center">
          <h5 className="m-0">Case Messages</h5>
        </div>
        <div
          className="card-body p-3"
          style={{
            maxHeight: "500px",
            overflowY: "auto",
            backgroundColor: "#f8f9fa",
          }}
        >
          {loading ? (
            <div className="d-flex justify-content-center">
              <div className="spinner-border text-primary" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          ) : (
            <div className="message-container">
              {messages.map((msg, index) => (
                <div
                  key={index}
                  className={`d-flex mb-3 ${
                    msg.from === "ADMIN"
                      ? "justify-content-end"
                      : "justify-content-start"
                  }`}
                >
                  <div
                    className={`p-3 rounded shadow-sm ${
                      msg.from === "ADMIN"
                        ? "bg-primary text-white"
                        : "bg-light text-dark"
                    }`}
                    style={{ maxWidth: "60%" }}
                  >
                    <strong>{msg.from}</strong>
                    <p className="mb-1">{msg.message}</p>
                    <small className="text-muted">
                      {new Date(msg.created_at).toLocaleString()}
                    </small>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>

        {/* Message Input Area */}
        <div className="card-footer bg-white">
          <div className="d-flex">
            <input
              type="text"
              className="form-control me-2"
              value={newMessage}
              onChange={(e) => setNewMessage(e.target.value)}
              placeholder="Type a message..."
            />
            <button className="btn btn-primary" onClick={handleSendMessage}>
              Send
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CaseMessages;
