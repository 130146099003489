import React, { useEffect, useState } from "react";
import axios from "axios";
import { useDispatch } from "react-redux";
import {
  addConfirmationCode,
  removeConfirmationCode,
} from "../utils/ConfirmationSlice";
import { useNavigate } from "react-router-dom";
import { Box, Typography, CircularProgress, Button } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";

const RecentFeedback = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [progress, setProgress] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageSize, setPageSize] = useState(10);

  useEffect(() => {
    setLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}recent_feedback`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        setProgress(response?.data?.data || []);
      })
      .catch((error) => {
        console.error("Error fetching feedback information:", error);
        setProgress([]);
      })
      .finally(() => {
        setLoading(false);
      });

    dispatch(removeConfirmationCode());
  }, [dispatch]);

  const handleChange = (confirmation_code) => {
    dispatch(addConfirmationCode(confirmation_code));
    navigate("/");
  };

  const handleViewMessages = (cases_id) => {
    navigate(`/cases/${cases_id}/messages`);
  };

  const columns = [
    { field: "sNo", headerName: "S.No", width: 80 },
    {
      field: "confirmation_code",
      headerName: "Confirmation Code",
      flex: 1,
      renderCell: ({ row }) => (
        <Typography
          color="#4A51F8"
          sx={{ cursor: "pointer", pt: 2 }}
          onClick={() => handleChange(row.confirmation_code)}
        >
          {row.confirmation_code}
        </Typography>
      ),
    },
    { field: "country_phone_code", headerName: "Phone Code", flex: 1 },
    { field: "phone_number", headerName: "Phone Number", flex: 1 },
    { field: "email", headerName: "Email", flex: 1 },
    { field: "feedback_subject", headerName: "Case Subject", flex: 1 },
    { field: "message", headerName: "Message", flex: 1 },
    { field: "country_name", headerName: "Country Name", flex: 1 },
    {
      field: "created_at",
      headerName: "Created At",
      flex: 1,
      renderCell: ({ row }) =>
        new Date(row.created_at).toLocaleDateString("en-GB"),
    },
    {
      field: "view_message",
      headerName: "Message",
      flex: 1,
      renderCell: ({ row }) => (
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={() => handleViewMessages(row.cases_id)}
        >
          View
        </Button>
      ),
    },
  ];

  return (
    <Box
      sx={{ mt: 4, p: 3, bgcolor: "#f9f9f9", borderRadius: 3, boxShadow: 2 }}
    >
      <Typography
        variant="h5"
        sx={{ fontWeight: "bold", mb: 2, color: "#4A51F8" }}
      >
        Recent Cases
      </Typography>

      {loading ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height={200}
        >
          <CircularProgress />
        </Box>
      ) : progress.length === 0 ? (
        <Typography textAlign="center" mt={3}>
          No Cases Found.
        </Typography>
      ) : (
        <DataGrid
          rows={progress.map((row, index) => ({ ...row, sNo: index + 1 }))}
          columns={columns}
          getRowId={(row) => row.cases_id}
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          initialState={{ pagination: { paginationModel: { pageSize: 10 } } }}
          pageSizeOptions={[10]}
          sx={{
            "& .MuiDataGrid-columnHeader": {
              backgroundColor: "#4A51F8",
              color: "white",
            },
            "& .MuiDataGrid-columnHeaderTitle": { color: "white" },
          }}
        />
      )}
    </Box>
  );
};

export default RecentFeedback;
