import React, { useState, useEffect } from "react";
import {
  Box,
  Select,
  MenuItem,
  Button,
  Typography,
  CircularProgress,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import AirportModal from "./AirportModal"; // Import the modal component

const AirportManager = () => {
  const [countries, setCountries] = useState([]);
  const [airports, setAirports] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [loading, setLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [selectedAirport, setSelectedAirport] = useState(null);
  const [pageSize, setPageSize] = useState(10);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}country`, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      })
      .then((response) => setCountries(response.data.data || []))
      .catch((error) => console.error("Error fetching countries:", error));
  }, []);

  useEffect(() => {
    if (!selectedCountry) return;
    setLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}airports/${selectedCountry}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      })
      .then((response) => setAirports(response.data.data || []))
      .catch((error) => console.error("Error fetching airports:", error))
      .finally(() => setLoading(false));
  }, [selectedCountry]);

  const handleOpenModal = (airport = null) => {
    if (airport) {
      setEditMode(true);
      setSelectedAirport(airport);
    } else {
      setEditMode(false);
      setSelectedAirport(null);
    }
    setModalOpen(true);
  };

  const columns = [
    {
      field: "id",
      headerName: "SNo.",
      width: 80,
      headerClassName: "custom-header",
    },
    {
      field: "airport_iata_code",
      headerName: "IATA Code",
      flex: 1,
      headerClassName: "custom-header",
    },
    {
      field: "airport_name",
      headerName: "Airport Name",
      flex: 1,
      headerClassName: "custom-header",
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 120,
      headerClassName: "custom-header",
      renderCell: ({ row }) => (
        <Button
          size="small"
          variant="outlined"
          onClick={() => handleOpenModal(row)}
        >
          Edit
        </Button>
      ),
    },
  ];

  return (
    <Box
      sx={{ mt: 4, p: 3, bgcolor: "#f9f9f9", borderRadius: 3, boxShadow: 2 }}
    >
      <Typography
        variant="h5"
        sx={{ fontWeight: "bold", mb: 2, color: "#4A51F8" }}
      >
        Airport Manager
      </Typography>

      <Box display="flex" gap={2} mb={2}>
        <Select
          value={selectedCountry}
          onChange={(e) => setSelectedCountry(e.target.value)}
          displayEmpty
          fullWidth
        >
          <MenuItem value="">Select a country</MenuItem>
          {countries.map((country) => (
            <MenuItem key={country.country_id} value={country.country_id}>
              {country.country_name}
            </MenuItem>
          ))}
        </Select>

        <Button
          variant="contained"
          onClick={() => handleOpenModal()}
          sx={{ backgroundColor: "#4A51F8" }}
        >
          Add Airport
        </Button>
      </Box>

      {loading ? (
        <CircularProgress />
      ) : (
        <DataGrid
          rows={airports.map((airport, index) => ({
            id: index + 1,
            ...airport,
          }))}
          columns={columns}
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 10,
              },
            },
          }}
          pageSizeOptions={[10]}
          sx={{
            "& .custom-header": {
              backgroundColor: "#4A51F8",
              color: "white",
            },
            "& .MuiDataGrid-columnHeaderTitle": { color: "white" },
            "& .MuiDataGrid-sortIcon, & .MuiDataGrid-menuIconButton": {
              color: "#fff",
            },
            "& .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-columnHeader:focus-within":
              { outline: "none" },
          }}
        />
      )}

      <AirportModal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        editMode={editMode}
        airport={selectedAirport}
        refreshAirports={() => setSelectedCountry(selectedCountry)} // Re-fetch airports after adding/editing
      />
    </Box>
  );
};

export default AirportManager;
