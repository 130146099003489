import React, { useState, useEffect } from "react";
import {
  Box,
  Select,
  MenuItem,
  Button,
  Typography,
  CircularProgress,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import AirlineModal from "./AirlineModal";

const AirlineManager = () => {
  const [airlines, setAirlines] = useState([]);
  const [countries, setCountries] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [selectedAirline, setSelectedAirline] = useState(null);
  const [pageSize, setPageSize] = useState(10);

  useEffect(() => {
    setLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}airline`, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      })
      .then((response) => setAirlines(response.data.data || []))
      .catch((error) => console.error("Error fetching airlines:", error))
      .finally(() => setLoading(false));
  }, [modalOpen]);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}country`, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      })
      .then((response) => setCountries(response.data.data || []))
      .catch((error) => console.error("Error fetching countries:", error));
  }, []);

  const handleOpenModal = (airline = null) => {
    if (airline) {
      setEditMode(true);
      setSelectedAirline(airline);
    } else {
      setEditMode(false);
      setSelectedAirline(null);
    }
    setModalOpen(true);
  };

  const columns = [
    { field: "id", headerName: "SNo.", width: 80 },
    { field: "airline_name", headerName: "Airline Name", flex: 1 },
    { field: "airline_iata_code", headerName: "IATA Code", flex: 1 },
    { field: "country_name", headerName: "Country", flex: 1 },
    {
      field: "domestic",
      headerName: "Domestic",
      width: 120,
      renderCell: ({ row }) => (row.domestic ? "Yes" : "No"),
    },
    {
      field: "international",
      headerName: "International",
      width: 140,
      renderCell: ({ row }) => (row.international ? "Yes" : "No"),
    },
    {
      field: "active",
      headerName: "Active",
      width: 100,
      renderCell: ({ row }) => (row.active ? "Yes" : "No"),
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 120,
      renderCell: ({ row }) => (
        <Button
          variant="contained"
          onClick={() => handleOpenModal(row)}
          sx={{ backgroundColor: "#4A51F8" }}
        >
          Edit
        </Button>
      ),
    },
  ];

  return (
    <Box
      sx={{ mt: 4, p: 3, bgcolor: "#f9f9f9", borderRadius: 3, boxShadow: 2 }}
    >
      <Typography
        variant="h5"
        sx={{ fontWeight: "bold", mb: 2, color: "#4A51F8" }}
      >
        Manage Airlines
      </Typography>
      <Button
        variant="contained"
        onClick={() => handleOpenModal()}
        sx={{ mb: 2, backgroundColor: "#4A51F8" }}
      >
        Add Airline
      </Button>
      {loading ? (
        <CircularProgress />
      ) : (
        <DataGrid
          rows={airlines.map((airline, i) => ({ ...airline, id: i + 1 }))}
          columns={columns}
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          initialState={{ pagination: { paginationModel: { pageSize: 10 } } }}
          pageSizeOptions={[10]}
          sx={{
            "& .MuiDataGrid-columnHeader": {
              backgroundColor: "#4A51F8",
              color: "white",
            },
          }}
        />
      )}
      <AirlineModal
        open={modalOpen}
        handleClose={() => setModalOpen(false)}
        editMode={editMode}
        selectedAirline={selectedAirline}
        countries={countries}
        fetchAirlines={() => {
          setLoading(true);
          axios
            .get(`${process.env.REACT_APP_API_URL}airline`, {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            })
            .then((response) => setAirlines(response.data.data || []))
            .catch((error) => console.error("Error fetching airlines:", error))
            .finally(() => setLoading(false));
        }}
      />
    </Box>
  );
};

export default AirlineManager;
