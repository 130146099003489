import React, { useEffect, useState } from "react";
import axios from "axios";
import { useDispatch } from "react-redux";
import { Box, Typography, CircularProgress } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import {
  addConfirmationCode,
  removeConfirmationCode,
} from "../utils/ConfirmationSlice";

const Progress = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [progress, setProgress] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageSize, setPageSize] = useState(10);

  useEffect(() => {
    setLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}progress`, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      })
      .then((response) => setProgress(response?.data || []))
      .catch((error) => console.error("Error fetching progress:", error))
      .finally(() => setLoading(false));

    dispatch(removeConfirmationCode());
  }, [dispatch]);

  const handleChange = (confirmation_code) => {
    dispatch(addConfirmationCode(confirmation_code));
    navigate("/");
  };

  const columns = [
    {
      field: "sNo",
      headerName: "S.No",
      width: 80,
      headerClassName: "custom-header",
    },
    {
      field: "confirmation_code",
      headerName: "Confirmation Code",
      flex: 1,
      headerClassName: "custom-header",
      renderCell: ({ row }) => (
        <Typography
          color="#4A51F8"
          sx={{
            cursor: "pointer",
            pt: 2,
            textDecoration: "underline",
            fontWeight: "bold",
          }}
          onClick={() => handleChange(row.confirmation_code)}
        >
          {row.confirmation_code}
        </Typography>
      ),
    },
    {
      field: "date_of_travel",
      headerName: "Date of Boarding",
      flex: 1,
      headerClassName: "custom-header",
    },
    {
      field: "start_date_of_travel",
      headerName: "Start Date of Travel",
      headerClassName: "custom-header",
      flex: 1,
    },
    {
      field: "end_date_of_travel",
      headerName: "End Date of Travel",
      flex: 1,
      headerClassName: "custom-header",
    },
    {
      field: "flight_code",
      headerName: "Flight Code",
      flex: 1,
      headerClassName: "custom-header",
    },
    {
      field: "from",
      headerName: "From",
      headerClassName: "custom-header",
      flex: 1,
      renderCell: ({ row }) =>
        `${row.from_airport_name}, ${row.from_city_name} (${row.from_airport_iata_code})`,
    },
    {
      field: "to",
      headerName: "To",
      headerClassName: "custom-header",
      flex: 1,
      renderCell: ({ row }) =>
        `${row.to_airport_name}, ${row.to_city_name} (${row.to_airport_iata_code})`,
    },
  ];

  return (
    <Box
      sx={{ mt: 4, p: 3, bgcolor: "#f9f9f9", borderRadius: 3, boxShadow: 2 }}
    >
      <Typography
        variant="h5"
        sx={{ fontWeight: "bold", mb: 2, color: "#4A51F8" }}
      >
        Progress
      </Typography>
      {loading ? (
        <CircularProgress />
      ) : (
        <DataGrid
          rows={progress.map((row, index) => ({ ...row, sNo: index + 1 }))}
          columns={columns}
          getRowId={(row) => row.confirmation_code}
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          initialState={{ pagination: { paginationModel: { pageSize: 10 } } }}
          sx={{
            "& .custom-header": { backgroundColor: "#4A51F8", color: "white" },
            "& .MuiDataGrid-columnHeaderTitle": { color: "white" },
            "& .MuiDataGrid-sortIcon, & .MuiDataGrid-menuIconButton": {
              color: "#fff",
            },
            "& .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-columnHeader:focus-within":
              { outline: "none" },
          }}
        />
      )}
    </Box>
  );
};

export default Progress;
