import React, { useEffect, useState } from "react";
import axios from "axios";
import { useDispatch } from "react-redux";
import {
  addConfirmationCode,
  removeConfirmationCode,
} from "../utils/ConfirmationSlice";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Typography,
  CircularProgress,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";

const Uploaded = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [progress, setProgress] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const [filter, setFilter] = useState("IN_PROGRESS");

  useEffect(() => {
    const fetchRequests = async () => {
      setLoading(true);
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}companion`,
          { status: filter },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        setProgress(response?.data?.data || []);
      } catch (error) {
        console.error("Error fetching requests:", error);
        setProgress([]);
      } finally {
        setLoading(false);
      }
    };

    fetchRequests();
    dispatch(removeConfirmationCode());
  }, [filter, dispatch]);

  const handleChange = (confirmation_code) => {
    dispatch(addConfirmationCode(confirmation_code));
    navigate("/");
  };

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
  };

  const columns = [
    {
      field: "sNo",
      headerName: "S.No",
      width: 80,
      headerClassName: "custom-header",
    },
    {
      field: "confirmation_code",
      headerName: "Confirmation Code",
      headerClassName: "custom-header",
      flex: 1,
      renderCell: ({ row }) => (
        <Typography
          color="#4A51F8"
          sx={{ cursor: "pointer", pt: 2 }}
          onClick={() => handleChange(row.confirmation_code)}
        >
          {row.confirmation_code}
        </Typography>
      ),
    },
    {
      field: "start_date_of_travel",
      headerName: "Start Date",
      headerClassName: "custom-header",
      flex: 1,
      renderCell: ({ row }) =>
        new Date(row.start_date_of_travel).toLocaleDateString("en-GB"),
    },
  ];

  return (
    <Box
      sx={{ mt: 4, p: 3, bgcolor: "#f9f9f9", borderRadius: 3, boxShadow: 2 }}
    >
      <Typography
        variant="h5"
        sx={{ fontWeight: "bold", mb: 2, color: "#4A51F8" }}
      >
        Paid Companions
      </Typography>

      <FormControl sx={{ mb: 2, minWidth: 200 }}>
        <InputLabel>Status</InputLabel>
        <Select value={filter} onChange={handleFilterChange} label="Status">
          <MenuItem value="IN_PROGRESS">In Progress</MenuItem>
          <MenuItem value="SUBMITTED">Submitted</MenuItem>
          <MenuItem value="UNDER_REVIEW">Under Review</MenuItem>
          <MenuItem value="DECLINED">Declined</MenuItem>
          <MenuItem value="APPROVED">Approved</MenuItem>
        </Select>
      </FormControl>

      {loading ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height={200}
        >
          <CircularProgress />
        </Box>
      ) : progress.length === 0 ? (
        <Typography textAlign="center" mt={3}>
          No Travel Plan Found.
        </Typography>
      ) : (
        <DataGrid
          rows={progress.map((row, index) => ({ ...row, sNo: index + 1 }))}
          columns={columns}
          getRowId={(row) => row.confirmation_code}
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          initialState={{ pagination: { paginationModel: { pageSize: 10 } } }}
          pageSizeOptions={[10]}
          sx={{
            "& .custom-header": { backgroundColor: "#4A51F8", color: "white" },
            "& .MuiDataGrid-columnHeaderTitle": { color: "white" }, // Column text color
            "& .MuiDataGrid-sortIcon, & .MuiDataGrid-menuIconButton": {
              color: "#fff",
            }, // Sorting & Menu icon
            "& .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-columnHeader:focus-within":
              { outline: "none" }, // Remove unwanted focus styles
          }}
        />
      )}
    </Box>
  );
};

export default Uploaded;
