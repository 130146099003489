import React, { useState } from "react";
import axios from "axios";
import {
  TextField,
  Button,
  Container,
  Paper,
  Box,
  Typography,
} from "@mui/material";
import logo from "../assets/MMFLogo_SVG.svg";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}api/login`,
        { email, password }
      );

      if (response.status === 200) {
        localStorage.setItem("token", response.data.data.token);
        setEmail("");
        setPassword("");
        window.location.replace("/");
      } else {
        alert("Login failed");
      }
    } catch (error) {
      alert("Login failed");
    }
  };

  return (
    <Box
      sx={{
        minHeight: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        background: "#FFF",
        padding: 2,
      }}
    >
      <Container maxWidth="xs">
        <Paper
          elevation={5}
          sx={{
            padding: 4,
            borderRadius: 3,
            textAlign: "center",
            background: "#fff",
            boxShadow: "10 15px 35px rgba(0, 0, 0, 0.1)",
          }}
        >
          <Box display="flex" justifyContent="center" mb={2}>
            <img src={logo} alt="logo" width={80} />
          </Box>
          <Typography variant="h5" fontWeight="bold" gutterBottom>
            Sign in to your account
          </Typography>

          <form onSubmit={handleLogin}>
            <TextField
              fullWidth
              label="Email"
              variant="outlined"
              margin="normal"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              sx={{ borderRadius: 1 }}
            />
            <TextField
              fullWidth
              label="Password"
              type="password"
              variant="outlined"
              margin="normal"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              sx={{ borderRadius: 1 }}
            />
            <Button
              type="submit"
              variant="contained"
              fullWidth
              sx={{
                mt: 3,
                background: "#4A51F8",
                color: "#fff",
                padding: "10px",
                fontWeight: "bold",
                borderRadius: 2,
                "&:hover": {
                  background: "#4A51F8",
                },
              }}
            >
              Login
            </Button>
          </form>
        </Paper>
      </Container>
    </Box>
  );
};

export default Login;
