import React, { useEffect, useState } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import axios from "axios";

import "bootstrap/dist/css/bootstrap.min.css";
import Sidebar from "../components/Sidebar";
import { Navbar } from "react-bootstrap";
import NavbarComponent from "../components/Navbar";

const apiURL =
  process.env.REACT_APP_API_URL || "http://localhost:5000/admincrm/";

const CountryChart = () => {
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [chartData, setChartData] = useState([]);
  const [chartType, setChartType] = useState("country");

  const [chartOptions, setChartOptions] = useState({
    chart: {
      type: "column",
    },
    title: {
      text: "Country Count Chart",
    },
    xAxis: {
      categories: [],
      title: {
        text: "Categories",
      },
    },
    yAxis: {
      title: {
        text: "Count",
      },
    },
    series: [
      {
        name: "Count",
        data: [],
      },
    ],
  });

  const handleSubmit = async () => {
    let apiEndpoint = "";

    switch (chartType) {
      case "country":
        apiEndpoint = "charts/userRegistrations";
        break;
      case "age":
        apiEndpoint = "charts/ageRegistrations";
        break;
      case "gender":
        apiEndpoint = "charts/genderRegistrations";
        break;
      case "traveller":
        apiEndpoint = "charts/travellerRegistrations";
        break;
      case "daily":
        apiEndpoint = "charts/dailyRegistrations";
        break;
      default:
        break;
    }

    try {
      const response = await axios.post(
        `${apiURL}${apiEndpoint}`,
        { fromDate, toDate },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      setChartData(response.data.data);
      updateChartOptions(response.data.data);
    } catch (error) {
      alert(error?.response?.data?.message || "Something went wrong!");
    }
  };

  const updateChartOptions = (data) => {
    let xAxisTitle = "Categories";
    let categories = [];
    let countData = [];

    switch (chartType) {
      case "country":
        xAxisTitle = "Country";
        categories = data?.map((entry) => entry.Country);
        countData = data?.map((entry) => entry.count);
        break;
      case "age":
        xAxisTitle = "AgeRange";
        categories = data?.map((entry) => entry.AgeRange);
        countData = data?.map((entry) => entry.count);
        break;
      case "gender":
        xAxisTitle = "Gender";
        categories = data?.map((entry) => entry.Gender);
        countData = data?.map((entry) => entry.count);
        break;
      case "traveller":
        xAxisTitle = "Travellers";
        categories = data?.map((entry) => entry.Travellers);
        countData = data?.map((entry) => entry.count);
        break;
      case "daily":
        xAxisTitle = "Date";
        categories = data?.map(
          (entry) =>
            `${entry.the_year}-${String(entry.month_of_the_year).padStart(
              2,
              "0"
            )}-${String(entry.day_of_the_month).padStart(2, "0")}`
        );
        countData = data?.map((entry) => entry.count);
        break;
      default:
        break;
    }

    setChartOptions({
      ...chartOptions,
      xAxis: {
        ...chartOptions.xAxis,
        categories,
        title: { text: xAxisTitle },
      },
      series: [
        {
          ...chartOptions.series[0],
          data: countData,
        },
      ],
    });
  };

  useEffect(() => {
    handleSubmit();
  }, [chartType]);

  return (
    <>
      <div className="d-flex">
        <Sidebar />
        <div className="flex-grow-1">
          <div className="container mt-5">
            <div className="row">
              <div className="col-md-6 mx-auto">
                <h2 className="text-center mb-4">Registration Chart</h2>

                {/* Chart Type Selection */}
                <div className="mb-3">
                  <label htmlFor="chartType" className="form-label me-2">
                    Chart Type:
                  </label>
                  <select
                    className="form-select"
                    id="chartType"
                    value={chartType}
                    onChange={(e) => setChartType(e.target.value)}
                  >
                    <option value="country">
                      User Registrations per Country
                    </option>
                    <option value="age">Travellers by Age</option>
                    <option value="gender">Travellers by Gender</option>
                    <option value="traveller">Travellers per Travel</option>
                    <option value="daily">Daily Registrations</option>
                  </select>
                </div>

                {/* Date Inputs */}
                <div className="mb-3">
                  <label htmlFor="fromDate" className="form-label me-2">
                    From Date:
                  </label>
                  <input
                    type="date"
                    className="form-control"
                    id="fromDate"
                    value={fromDate}
                    onChange={(e) => setFromDate(e.target.value)}
                  />
                </div>

                <div className="mb-3">
                  <label htmlFor="toDate" className="form-label me-2">
                    To Date:
                  </label>
                  <input
                    type="date"
                    className="form-control"
                    id="toDate"
                    value={toDate}
                    onChange={(e) => setToDate(e.target.value)}
                  />
                </div>

                {/* Submit Button */}
                <button className="btn btn-primary" onClick={handleSubmit}>
                  Submit
                </button>

                {/* Chart */}
                <HighchartsReact
                  highcharts={Highcharts}
                  options={chartOptions}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CountryChart;
