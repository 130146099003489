import React, { useState, useEffect } from "react";
import {
  Modal,
  Box,
  Typography,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Switch,
  Button,
  CircularProgress,
} from "@mui/material";
import axios from "axios";

const AirlineModal = ({
  open,
  onClose,
  editMode,
  airline,
  refreshAirlines,
}) => {
  const [airlineName, setAirlineName] = useState("");
  const [airlineIataCode, setAirlineIataCode] = useState("");
  const [countryId, setCountryId] = useState("");
  const [domestic, setDomestic] = useState(false);
  const [international, setInternational] = useState(false);
  const [active, setActive] = useState(false);
  const [countries, setCountries] = useState([]);
  const [loading, setLoading] = useState(false);

  // Fetch country list on mount
  useEffect(() => {
    setLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}country`, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      })
      .then((response) => setCountries(response.data.data || []))
      .catch((error) => console.error("Error fetching countries:", error))
      .finally(() => setLoading(false));
  }, []);

  // Prefill form when editing an airline
  useEffect(() => {
    if (editMode && airline) {
      setAirlineName(airline.airline_name || "");
      setAirlineIataCode(airline.airline_iata_code || "");
      setCountryId(airline.country_id || "");
      setDomestic(!!airline.domestic);
      setInternational(!!airline.international);
      setActive(!!airline.active);
    } else {
      setAirlineName("");
      setAirlineIataCode("");
      setCountryId("");
      setDomestic(false);
      setInternational(false);
      setActive(false);
    }
  }, [editMode, airline]);

  // Handle form submission
  const handleSave = () => {
    if (!airlineName || !airlineIataCode || !countryId) {
      alert("Please fill all required fields.");
      return;
    }

    const formData = {
      airline_name: airlineName,
      airline_iata_code: airlineIataCode,
      country_id: countryId,
      domestic: domestic ? 1 : 0,
      international: international ? 1 : 0,
      active: active ? 1 : 0,
      priority: 99,
    };

    const apiUrl = editMode
      ? `${process.env.REACT_APP_API_URL}airline/${airline.airline_id}`
      : `${process.env.REACT_APP_API_URL}createAirline`;

    axios
      .post(apiUrl, formData, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      })
      .then(() => {
        refreshAirlines();
        onClose();
      })
      .catch((error) => console.error("Error saving airline:", error));
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
        }}
      >
        <Typography variant="h6" mb={2}>
          {editMode ? "Edit Airline" : "Add Airline"}
        </Typography>

        <TextField
          fullWidth
          label="Airline Name"
          value={airlineName}
          onChange={(e) => setAirlineName(e.target.value)}
          margin="normal"
          required
        />

        <TextField
          fullWidth
          label="IATA Code"
          value={airlineIataCode}
          onChange={(e) => setAirlineIataCode(e.target.value)}
          margin="normal"
          required
        />

        <FormControl fullWidth margin="normal">
          <InputLabel>Country</InputLabel>
          <Select
            value={countryId}
            onChange={(e) => setCountryId(e.target.value)}
            required
          >
            {loading ? (
              <MenuItem disabled>
                <CircularProgress size={20} />
              </MenuItem>
            ) : (
              countries.map((country) => (
                <MenuItem key={country.country_id} value={country.country_id}>
                  {country.country_name}
                </MenuItem>
              ))
            )}
          </Select>
        </FormControl>

        <Box display="flex" alignItems="center" mt={2}>
          <Typography>Domestic</Typography>
          <Switch
            checked={domestic}
            onChange={(e) => setDomestic(e.target.checked)}
          />
        </Box>

        <Box display="flex" alignItems="center">
          <Typography>International</Typography>
          <Switch
            checked={international}
            onChange={(e) => setInternational(e.target.checked)}
          />
        </Box>

        <Box display="flex" alignItems="center">
          <Typography>Active</Typography>
          <Switch
            checked={active}
            onChange={(e) => setActive(e.target.checked)}
          />
        </Box>

        <Box mt={3} display="flex" justifyContent="space-between">
          <Button onClick={onClose} variant="outlined">
            Cancel
          </Button>
          <Button onClick={handleSave} variant="contained" color="primary">
            Save
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default AirlineModal;
