import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Select,
  MenuItem,
  Button,
  Box,
  InputLabel,
  FormControl,
} from "@mui/material";
import axios from "axios";

const CityModal = ({
  open,
  handleClose,
  editMode,
  selectedCity,
  fetchCities,
  selectState,
  selectCountry,
}) => {
  console.log(selectState, selectCountry);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(selectCountry);
  const [selectedState, setSelectedState] = useState(selectState);
  const [cityName, setCityName] = useState("");
  const [cityCode, setCityCode] = useState("");
  const [cityImageUrl, setCityImageUrl] = useState("");
  // Update cityImageUrl when cityName changes
  useEffect(() => {
    if (cityName || cityCode) {
      setCityImageUrl(
        `${process.env.REACT_APP_IMAGE_CITY_URL}${cityCode}.webp`
      );
    }
  }, [cityName, cityCode]);

  useEffect(() => {
    // Fetch Countries
    axios
      .get(`${process.env.REACT_APP_API_URL}country`, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      })
      .then((response) => setCountries(response.data.data || []))
      .catch((error) => console.error("Error fetching countries:", error));
  }, []);

  useEffect(() => {
    if (!selectedCountry) return;

    axios
      .get(`${process.env.REACT_APP_API_URL}state/${selectedCountry}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      })
      .then((response) => setStates(response.data.data || []))
      .catch((error) => console.error("Error fetching states:", error));
  }, [selectedCountry]);

  useEffect(() => {
    if (editMode && selectedCity) {
      setCityName(selectedCity.city_name);
      setCityCode(selectedCity.city_code);
      setCityImageUrl(selectedCity.city_image_url || "");
      setSelectedCountry(selectedCity.country_id);
      setSelectedState(selectedCity.state_id);
    } else {
      setCityName("");
      setCityCode("");
      setCityImageUrl("");
      setSelectedCountry("");
      setSelectedState("");
    }
  }, [editMode, selectedCity]);

  const handleSubmit = async () => {
    alert(cityImageUrl);
    if (!cityName || !selectedState) {
      alert("All fields are required!");
      return;
    }

    const payload = {
      city_name: cityName,
      city_code: cityCode,
      city_image_url: cityImageUrl,
      state_id: selectedState,
    };

    try {
      if (editMode) {
        await axios.post(
          `${process.env.REACT_APP_API_URL}city/${selectedCity.city_id}`,
          payload,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        alert("City updated successfully!");
      } else {
        await axios.post(`${process.env.REACT_APP_API_URL}city`, payload, {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        });
        alert("City added successfully!");
      }

      handleClose();
      fetchCities(); // Refresh city list
    } catch (error) {
      console.error("Error submitting city:", error);
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
      <DialogTitle>{editMode ? "Edit City" : "Add City"}</DialogTitle>
      <DialogContent>
        <Box display="flex" flexDirection="column" gap={2} mt={1}>
          {/* Country Selection */}
          {!editMode && (
            <FormControl fullWidth>
              <InputLabel>Country</InputLabel>
              <Select
                value={selectedCountry}
                onChange={(e) => setSelectedCountry(e.target.value)}
              >
                <MenuItem value="">Select a Country</MenuItem>
                {countries.map((country) => (
                  <MenuItem key={country.country_id} value={country.country_id}>
                    {country.country_name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}

          {!editMode && (
            <FormControl fullWidth>
              <InputLabel>State</InputLabel>
              <Select
                value={selectedState}
                onChange={(e) => setSelectedState(e.target.value)}
                disabled={!selectedCountry}
              >
                <MenuItem value="">Select a State</MenuItem>
                {states.map((state) => (
                  <MenuItem key={state.state_id} value={state.state_id}>
                    {state.state_name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}

          {/* City Name */}
          <TextField
            label="City Name"
            fullWidth
            value={cityName}
            onChange={(e) => {
              setCityName(e.target.value);
              setCityCode(e.target.value.toLowerCase().replace(/\s+/g, "-"));
            }}
          />

          {/* City Code */}
          <TextField
            label="City Code"
            fullWidth
            value={cityCode}
            onChange={(e) => {
              setCityCode(e.target.value.toLowerCase().replace(/\s+/g, "-"));
            }}
          />

          {/* City Image URL */}
          {/* City Image URL (Disabled) */}
          <TextField
            label="City Image URL"
            fullWidth
            value={cityImageUrl}
            disabled
          />
        </Box>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button variant="contained" onClick={handleSubmit}>
          {editMode ? "Update City" : "Add City"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CityModal;
