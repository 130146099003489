import React, { useState } from "react";
import {
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Collapse,
  Box,
  Typography,
  Divider,
  Button,
} from "@mui/material";
import { Link } from "react-router-dom";
import { ExpandLess, ExpandMore, Logout } from "@mui/icons-material";
import logo from "../assets/MMFLogo_SVG.svg";

const Sidebar = () => {
  const [openSections, setOpenSections] = useState({});

  const handleSectionToggle = (section) => {
    setOpenSections((prev) => ({ ...prev, [section]: !prev[section] }));
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    window.location.replace("/login");
  };

  return (
    <Drawer
      variant="permanent"
      sx={{
        width: 340,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: 320,
          backgroundColor: "#fff",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          paddingLeft: "10px",
          paddingRight: "10px",
          boxShadow: "4px 0 10px rgba(0, 0, 0, 0.1)",
        },
      }}
    >
      {/* Logo and Title */}
      <Box sx={{ textAlign: "center", py: 1.5 }}>
        <img
          src={logo}
          alt="MMF Logo"
          style={{ width: "100px", marginBottom: "10px" }}
        />
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          MatchMyFight
        </Typography>
      </Box>

      {/* Sidebar Menu */}
      <List>
        {/* CRM Section */}
        <ListItemButton onClick={() => handleSectionToggle("CRM")}>
          <ListItemText
            primary={
              <Typography sx={{ fontSize: "1.2rem", fontWeight: "bold" }}>
                CRM
              </Typography>
            }
          />
          {openSections["CRM"] ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={openSections["CRM"]} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {[
              { name: "Search by Confirmation Code", link: "/" },
              { name: "Search by Phone Number", link: "/phone-number" },
              { name: "Search Travel Plans", link: "/search-city" },
              { name: "Travels In-Progress", link: "/progress" },
              { name: "Tickets", link: "/ticket/uploaded" },
              { name: "Paid Companions", link: "/companion" },
              { name: "Recent Bookings", link: "/recent-booking" },
              { name: "Recent Cases", link: "/recent-feedbck" },
              { name: "Recent Requests", link: "/recent-request" },
              { name: "Users", link: "/user" },
              { name: "Review Flights", link: "/flight-enable" },
            ].map((item, index) => (
              <React.Fragment key={index}>
                <ListItem disablePadding>
                  <ListItemButton
                    component={Link}
                    to={item.link}
                    sx={{ pl: 4, "&:hover": { backgroundColor: "#f0f0f0" } }}
                  >
                    <ListItemText primary={item.name} />
                  </ListItemButton>
                </ListItem>
                {index < 11 && <Divider sx={{ backgroundColor: "#000000" }} />}
              </React.Fragment>
            ))}
          </List>
        </Collapse>

        <Divider sx={{ backgroundColor: "#000000" }} />

        {/* Report Section */}
        <ListItemButton onClick={() => handleSectionToggle("Report")}>
          <ListItemText
            primary={
              <Typography sx={{ fontSize: "1.2rem", fontWeight: "bold" }}>
                Report
              </Typography>
            }
          />
          {openSections["Report"] ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={openSections["Report"]} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem disablePadding>
              <ListItemButton
                component={Link}
                to="/chart"
                sx={{ pl: 4, "&:hover": { backgroundColor: "#f0f0f0" } }}
              >
                <ListItemText primary="Charts" />
              </ListItemButton>
            </ListItem>
          </List>
        </Collapse>

        <Divider sx={{ backgroundColor: "#000000" }} />

        {/* Admin Section */}
        <ListItemButton onClick={() => handleSectionToggle("Admin")}>
          <ListItemText
            primary={
              <Typography sx={{ fontSize: "1.2rem", fontWeight: "bold" }}>
                Admin
              </Typography>
            }
          />
          {openSections["Admin"] ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={openSections["Admin"]} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {[
              { name: "City", link: "/city" },
              { name: "State", link: "/state" },
              { name: "Country", link: "/country" },
              { name: "Airport", link: "/airport" },
              { name: "Airline", link: "/airline" },
              { name: "Flight", link: "/flight" },
              { name: "Age Range", link: "/age" },
              { name: "Language", link: "/language" },
              { name: "Area Of Offering", link: "/area-of-service" },
              { name: "Offering type", link: "/service" },
              { name: "Case Subject", link: "/feedback" },
              { name: "Transit Step", link: "/transit_step" },
              {
                name: "Airport Source Transit Step",
                link: "/airport_source_transit_step",
              },
              {
                name: "Airport Destination Transit Step",
                link: "/airport_destination_transit_step",
              },
              { name: "Transit Pricing", link: "/transit-pricing" },
            ].map((item, index) => (
              <>
                <ListItem key={index} disablePadding>
                  <ListItemButton
                    component={Link}
                    to={item.link}
                    sx={{ pl: 4, "&:hover": { backgroundColor: "#f0f0f0" } }}
                  >
                    <ListItemText primary={item.name} />
                  </ListItemButton>
                </ListItem>
                {index < 15 && <Divider sx={{ backgroundColor: "#000000" }} />}
              </>
            ))}
          </List>
        </Collapse>
      </List>

      <Divider sx={{ mt: "auto", mb: 2 }} />

      {/* Logout Button */}
      <Box sx={{ textAlign: "center", pb: 2 }}>
        <Button
          variant="contained"
          color="error"
          startIcon={<Logout />}
          fullWidth
          sx={{
            borderRadius: "8px",
            fontWeight: "bold",
            textTransform: "none",
            background: "#4A51F8",
            "&:hover": { backgroundColor: "#3f46e8" },
            py: 1.2,
          }}
          onClick={handleLogout}
        >
          Logout
        </Button>
      </Box>
    </Drawer>
  );
};

export default Sidebar;
