import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import axios from "axios";

const AirportModal = ({
  open,
  onClose,
  editMode,
  airport,
  refreshAirports,
}) => {
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [formData, setFormData] = useState({
    airport_name: "",
    airport_iata_code: "",
    latitude: "",
    longitude: "",
    country_id: "",
    state_id: "",
    city_id: "",
    active: true,
  });

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}country`, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      })
      .then((response) => setCountries(response.data.data || []))
      .catch((error) => console.error("Error fetching countries:", error));
  }, []);

  useEffect(() => {
    if (!formData.country_id) return;
    axios
      .get(`${process.env.REACT_APP_API_URL}state/${formData.country_id}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      })
      .then((response) => setStates(response.data.data || []))
      .catch((error) => console.error("Error fetching states:", error));
  }, [formData.country_id]);

  useEffect(() => {
    if (!formData.state_id) return;
    axios
      .get(`${process.env.REACT_APP_API_URL}city/${formData.state_id}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      })
      .then((response) => setCities(response.data.data || []))
      .catch((error) => console.error("Error fetching cities:", error));
  }, [formData.state_id]);

  useEffect(() => {
    if (editMode && airport) {
      setFormData({ ...airport });
    } else {
      setFormData({
        airport_name: "",
        airport_iata_code: "",
        latitude: "",
        longitude: "",
        country_id: "",
        state_id: "",
        city_id: "",
        active: true,
      });
    }
  }, [editMode, airport]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleCheckboxChange = (e) => {
    setFormData((prev) => ({ ...prev, active: e.target.checked }));
  };

  const handleSubmit = async () => {
    try {
      const url = editMode
        ? `${process.env.REACT_APP_API_URL}updateAirport`
        : `${process.env.REACT_APP_API_URL}airport`;

      await axios.post(url, formData, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      });

      alert(`Airport ${editMode ? "updated" : "added"} successfully!`);
      refreshAirports();
      onClose();
    } catch (error) {
      console.error("Error submitting airport:", error);
      alert("Error saving airport. Please try again.");
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>{editMode ? "Edit Airport" : "Add Airport"}</DialogTitle>
      <DialogContent>
        {/* Country Selection */}
        <FormControl fullWidth margin="dense">
          <InputLabel>Country</InputLabel>
          <Select
            name="country_id"
            value={formData.country_id}
            onChange={handleChange}
          >
            <MenuItem value="">Select a country</MenuItem>
            {countries.map((country) => (
              <MenuItem key={country.country_id} value={country.country_id}>
                {country.country_name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {/* State Selection */}
        <FormControl fullWidth margin="dense">
          <InputLabel>State</InputLabel>
          <Select
            name="state_id"
            value={formData.state_id}
            onChange={handleChange}
            disabled={!formData.country_id}
          >
            <MenuItem value="">Select a state</MenuItem>
            {states.map((state) => (
              <MenuItem key={state.state_id} value={state.state_id}>
                {state.state_name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {/* City Selection */}
        <FormControl fullWidth margin="dense">
          <InputLabel>City</InputLabel>
          <Select
            name="city_id"
            value={formData.city_id}
            onChange={handleChange}
            disabled={!formData.state_id}
          >
            <MenuItem value="">Select a city</MenuItem>
            {cities.map((city) => (
              <MenuItem key={city.city_id} value={city.city_id}>
                {city.city_name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {/* Airport Name */}
        <TextField
          label="Airport Name"
          fullWidth
          margin="dense"
          name="airport_name"
          value={formData.airport_name}
          onChange={handleChange}
          required
        />

        {/* IATA Code */}
        <TextField
          label="IATA Code"
          fullWidth
          margin="dense"
          name="airport_iata_code"
          value={formData.airport_iata_code}
          onChange={handleChange}
          required
        />

        {/* Latitude */}
        <TextField
          label="Latitude"
          fullWidth
          margin="dense"
          name="latitude"
          value={formData.latitude}
          onChange={handleChange}
        />

        {/* Longitude */}
        <TextField
          label="Longitude"
          fullWidth
          margin="dense"
          name="longitude"
          value={formData.longitude}
          onChange={handleChange}
        />

        {/* Active Checkbox */}
        <FormControlLabel
          control={
            <Checkbox
              checked={formData.active}
              onChange={handleCheckboxChange}
            />
          }
          label="Active"
        />
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSubmit} variant="contained">
          {editMode ? "Update Airport" : "Add Airport"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AirportModal;
