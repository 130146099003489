import React, { useEffect, useState } from "react";
import axios from "axios";
import { useDispatch } from "react-redux";
import {
  Box,
  Select,
  MenuItem,
  TextField,
  Button,
  Typography,
  CircularProgress,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { addConfirmationCode } from "../utils/ConfirmationSlice";

const RecentBooking = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [countryList, setCountryList] = useState([]);
  const [countryInfo, setCountryInfo] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [bookings, setBookings] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageSize, setPageSize] = useState(10);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}country`, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      })
      .then((response) => {
        setCountryList(response.data.data);
        if (response.data.data?.length) {
          const defaultCountry = response.data.data[0];
          setCountryInfo({
            countryCode: defaultCountry.country_code,
            countryName: defaultCountry.country_name,
            countryPhoneCode: defaultCountry.country_phone_code,
            countryFlagImagePath:
              process.env.REACT_APP_ASSETS_URL +
              defaultCountry.country_code +
              ".png",
          });
        }
      })
      .catch((error) => {
        console.error("Error fetching country list:", error);
      });
  }, []);

  const fetchBookings = () => {
    if (!countryInfo || !phoneNumber) {
      alert("Please select a country and enter a phone number.");
      return;
    }

    setLoading(true);

    axios
      .post(
        `${process.env.REACT_APP_API_URL}travelPlans/phone-number`,
        {
          country_phone_code: countryInfo.countryPhoneCode,
          phone_number: phoneNumber,
        },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      )
      .then((response) => {
        setBookings(response.data?.data || []);
      })
      .catch((error) => {
        console.error("Error fetching bookings:", error);
        setBookings([]);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleBookingClick = (confirmation_code) => {
    dispatch(addConfirmationCode(confirmation_code));
    navigate("/");
  };

  const columns = [
    {
      field: "sNo",
      headerName: "S.No",
      width: 80,
      headerClassName: "custom-header",
    },
    {
      field: "confirmation_code",
      headerName: "Confirmation Code",
      flex: 1,
      headerClassName: "custom-header",
      renderCell: ({ row }) => (
        <Typography
          color="#4A51F8"
          sx={{ cursor: "pointer", pt: 2 }}
          onClick={() => handleBookingClick(row.confirmation_code)}
        >
          {row.confirmation_code}
        </Typography>
      ),
    },
    {
      field: "start_date_of_travel",
      headerName: "Start Date",
      flex: 1,
      headerClassName: "custom-header",
      renderCell: ({ row }) =>
        new Date(row.start_date_of_travel).toLocaleDateString(),
    },
    {
      field: "created_at",
      headerName: "Created At",
      flex: 1,
      headerClassName: "custom-header",
      renderCell: ({ row }) => new Date(row.created_at).toLocaleDateString(),
    },
    {
      field: "active",
      headerName: "Active",
      flex: 1,
      headerClassName: "custom-header",
      renderCell: ({ row }) => (row.active ? "Yes" : "No"),
    },
  ];

  return (
    <Box
      sx={{
        mt: 4,
        p: 3,
        bgcolor: "#f9f9f9",
        borderRadius: 3,
        boxShadow: 2,
      }}
    >
      <Typography
        variant="h5"
        sx={{ fontWeight: "bold", mb: 2, color: "#4A51F8" }}
      >
        Recent Bookings
      </Typography>

      <Box display="flex" alignItems="center" gap={2} mb={3}>
        <Select
          value={countryInfo?.countryCode || ""}
          onChange={(e) => {
            const selectedCountry = countryList.find(
              (c) => c.country_code === e.target.value
            );
            setCountryInfo({
              countryCode: selectedCountry.country_code,
              countryName: selectedCountry.country_name,
              countryPhoneCode: selectedCountry.country_phone_code,
              countryFlagImagePath:
                process.env.REACT_APP_ASSETS_URL +
                selectedCountry.country_code +
                ".png",
            });
          }}
          displayEmpty
          sx={{ minWidth: 200, bgcolor: "white", borderRadius: 2 }}
        >
          {countryList.map((country, i) => (
            <MenuItem key={i} value={country.country_code}>
              <img
                src={
                  process.env.REACT_APP_ASSETS_URL +
                  country.country_code +
                  ".png"
                }
                alt=""
                width={20}
                style={{ marginRight: 10 }}
              />
              {country.country_name} ({country.country_phone_code})
            </MenuItem>
          ))}
        </Select>

        <TextField
          label="Phone Number"
          variant="outlined"
          value={phoneNumber}
          onChange={(e) => setPhoneNumber(e.target.value.replace(/[^\d]/g, ""))}
          inputProps={{ maxLength: 10 }}
          sx={{ bgcolor: "white", borderRadius: 2 }}
        />

        <Button
          variant="contained"
          onClick={fetchBookings}
          disabled={loading}
          sx={{
            backgroundColor: "#4A51F8",
            "&:hover": { backgroundColor: "#3a41d8" },
            p: 2,
          }}
        >
          {loading ? <CircularProgress size={24} /> : "Search"}
        </Button>
      </Box>

      <DataGrid
        rows={bookings.map((row, index) => ({ ...row, sNo: index + 1 }))}
        columns={columns}
        getRowId={(row) => row.confirmation_code}
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 10,
            },
          },
        }}
        pageSizeOptions={[10]}
        sx={{
          "& .custom-header": { backgroundColor: "#4A51F8", color: "white" },
          "& .MuiDataGrid-columnHeaderTitle": { color: "white" }, // Column text color
          "& .MuiDataGrid-sortIcon, & .MuiDataGrid-menuIconButton": {
            color: "#fff",
          }, // Sorting & Menu icon
          "& .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-columnHeader:focus-within":
            { outline: "none" }, // Remove unwanted focus styles
        }}
      />
    </Box>
  );
};

export default RecentBooking;
