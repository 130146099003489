import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Box,
  Button,
  Typography,
  Modal,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Switch,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";

const FlightInformation = () => {
  const [flightInfoData, setFlightInfoData] = useState([]);
  const [airports, setAirports] = useState([]);
  const [airlineOptions, setAirlineOptions] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [flightCode, setFlightCode] = useState("");
  const [airlineName, setAirlineName] = useState("");
  const [fromAirport, setFromAirport] = useState("");
  const [toAirport, setToAirport] = useState("");
  const [active, setActive] = useState(false);
  const [checkUpdate, setCheckUpdate] = useState(null);
  const [pageSize, setPageSize] = useState(10);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}flightinformation`, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      })
      .then((response) => setFlightInfoData(response.data.data || []))
      .catch((error) => console.error(error));

    axios
      .get(`${process.env.REACT_APP_API_URL}allAirline`, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      })
      .then((response) => setAirlineOptions(response.data.data || []))
      .catch((error) => console.error(error));

    axios
      .get(`${process.env.REACT_APP_API_URL}allAirports`, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      })
      .then((response) => setAirports(response.data.data || []))
      .catch((error) => console.error(error));
  }, [showModal]);

  const handleSaveFlightInfo = () => {
    if (!flightCode || !airlineName || !fromAirport || !toAirport) {
      alert("Please fill all the fields");
      return;
    }

    const formData = {
      from_airport_id: fromAirport,
      to_airport_id: toAirport,
      airline_id: airlineName,
      flight_code: flightCode,
      frequency: "M,T,W,Th,F,Sa,St",
      active: active ? 1 : 0,
      priority: "99",
    };

    const apiUrl = checkUpdate
      ? `${process.env.REACT_APP_API_URL}flightInformation/${checkUpdate}`
      : `${process.env.REACT_APP_API_URL}createFlightInformation`;

    axios
      .post(apiUrl, formData, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      })
      .then(() => {
        setShowModal(false);
        setCheckUpdate(null);
      })
      .catch((error) => console.error(error));
  };

  const handleUpdateFlightInfo = (flightId) => {
    setShowModal(true);
    setCheckUpdate(flightId);
    axios
      .get(`${process.env.REACT_APP_API_URL}flightInformation/${flightId}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      })
      .then((response) => {
        const flight = response.data.data;
        setFlightCode(flight.flight_code);
        setAirlineName(flight.airline_id);
        setFromAirport(flight.from_airport_id);
        setToAirport(flight.to_airport_id);
        setActive(flight.active === 1);
      })
      .catch((error) => console.error(error));
  };

  const columns = [
    {
      field: "id",
      headerName: "SNo.",
      flex: 1,
      headerClassName: "custom-header",
    },
    {
      field: "flight_code",
      headerName: "Flight Code",
      headerClassName: "custom-header",
      flex: 1,
      renderCell: (params) => (
        <Typography
          sx={{ cursor: "pointer", color: "blue", textDecoration: "underline" }}
          onClick={() => handleUpdateFlightInfo(params.row.flight_id)}
        >
          {params.value}
        </Typography>
      ),
    },
    {
      field: "airline_name",
      headerName: "Airline",
      flex: 1,
      headerClassName: "custom-header",
    },
    {
      field: "from_airport_name",
      headerName: "From",
      flex: 1,
      headerClassName: "custom-header",
    },
    {
      field: "to_airport_name",
      headerName: "To",
      flex: 1,
      headerClassName: "custom-header",
    },
    {
      field: "active",
      headerName: "Active",
      headerClassName: "custom-header",
      flex: 1,
      renderCell: (params) => (params.value ? "Yes" : "No"),
    },
  ];

  const filteredData = flightInfoData.filter((flight) =>
    flight?.flight_code.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <Box
      sx={{ mt: 4, p: 3, bgcolor: "#f9f9f9", borderRadius: 3, boxShadow: 2 }}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
      >
        <Typography
          variant="h5"
          sx={{ fontWeight: "bold", mb: 2, color: "#4A51F8" }}
        >
          Flight Information
        </Typography>
        <Box display="flex" gap={2}>
          <TextField
            variant="outlined"
            placeholder="Search flight"
            onChange={(e) => setSearchQuery(e.target.value)}
          />
          <Button variant="contained" onClick={() => setShowModal(true)}>
            Add Flight
          </Button>
        </Box>
      </Box>

      <DataGrid
        rows={filteredData.map((flight, i) => ({ ...flight, id: i + 1 }))}
        columns={columns}
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        initialState={{
          pagination: {
            paginationModel: { pageSize: 10 },
          },
        }}
        pageSizeOptions={[10]}
        sx={{
          "& .custom-header": {
            backgroundColor: "#4A51F8",
            color: "white",
          },
          "& .MuiDataGrid-columnHeaderTitle": { color: "white" },
          "& .MuiDataGrid-sortIcon, & .MuiDataGrid-menuIconButton": {
            color: "#fff",
          },
          "& .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-columnHeader:focus-within":
            { outline: "none" },
        }}
      />

      <Modal open={showModal} onClose={() => setShowModal(false)}>
        <Box
          sx={{
            width: 400,
            p: 3,
            bgcolor: "background.paper",
            borderRadius: 2,
            mx: "auto",
            mt: "10%",
          }}
        >
          <Typography variant="h6">
            {checkUpdate ? "Edit Flight" : "Add Flight"}
          </Typography>
          <FormControl fullWidth margin="normal">
            <InputLabel>Airline</InputLabel>
            <Select
              value={airlineName}
              onChange={(e) => setAirlineName(e.target.value)}
            >
              {airlineOptions.map((airline) => (
                <MenuItem key={airline.airline_id} value={airline.airline_id}>
                  {airline.airline_name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            fullWidth
            label="Flight Code"
            value={flightCode}
            onChange={(e) => setFlightCode(e.target.value)}
            margin="normal"
          />
          <Box display="flex" alignItems="center">
            <Typography>Active</Typography>
            <Switch
              checked={active}
              onChange={(e) => setActive(e.target.checked)}
            />
          </Box>
          <Box display="flex" justifyContent="space-between" mt={2}>
            <Button onClick={() => setShowModal(false)}>Cancel</Button>
            <Button variant="contained" onClick={handleSaveFlightInfo}>
              Save
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default FlightInformation;
