import React from "react";
import AgeRange from "../components/AgeRange";
import Sidebar from "../components/Sidebar";
import Feedback from "../components/Feedback";
import NavbarComponent from "../components/Navbar";

const FeedbackPage = () => {
  return (
    <>
      <div className="d-flex">
        <Sidebar />
        <div className="flex-grow-1">
          <Feedback />
        </div>
      </div>
    </>
  );
};

export default FeedbackPage;
