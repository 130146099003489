import React from "react";

import Sidebar from "../components/Sidebar";
import NavbarComponent from "../components/Navbar";
import TravelPlanByphone from "../components/TravelPlanByphoneNumber";

const TravelPlanByPhoneNumber = () => {
  return (
    <>
      <div className="d-flex">
        <Sidebar />
        <div className="flex-grow-1">
          <TravelPlanByphone />
        </div>
      </div>
    </>
  );
};

export default TravelPlanByPhoneNumber;
