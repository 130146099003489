import React from "react";

import Sidebar from "../components/Sidebar";

import NavbarComponent from "../components/Navbar";
import User from "../components/User";

const UsersPage = () => {
  return (
    <>
      <div className="d-flex">
        <Sidebar />
        <div className="flex-grow-1">
          <User />
        </div>
      </div>
    </>
  );
};

export default UsersPage;
