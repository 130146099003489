import React, { useEffect, useState } from "react";
import axios from "axios";
import { useDispatch } from "react-redux";
import {
  Box,
  Select,
  MenuItem,
  TextField,
  Button,
  Typography,
  CircularProgress,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { addConfirmationCode } from "../utils/ConfirmationSlice";

const RecentBooking = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [countries, setCountries] = useState([]);
  const [fromCities, setFromCities] = useState([]);
  const [toCities, setToCities] = useState([]);
  const [travelPlans, setTravelPlans] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageSize, setPageSize] = useState(5);

  // State for selected options
  const [selectedFromCountry, setSelectedFromCountry] = useState("");
  const [selectedToCountry, setSelectedToCountry] = useState("");
  const [fromCity, setFromCity] = useState("");
  const [toCity, setToCity] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  // Fetch countries on component mount
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}country`, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      })
      .then((response) => {
        setCountries(response.data.data);
      })
      .catch(() => console.error("Failed to fetch countries."));
  }, []);

  // Fetch cities based on selected country
  useEffect(() => {
    if (selectedFromCountry) fetchCities(selectedFromCountry, setFromCities);
  }, [selectedFromCountry]);

  useEffect(() => {
    if (selectedToCountry) fetchCities(selectedToCountry, setToCities);
  }, [selectedToCountry]);

  const fetchCities = (countryId, setCities) => {
    setLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}cities/${countryId}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      })
      .then((response) => {
        setCities(response.data.data);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        console.error("Failed to fetch cities.");
      });
  };

  const handleSubmit = () => {
    if (!fromCity || !toCity || !startDate || !endDate) {
      alert("All fields are required.");
      return;
    }

    setLoading(true);
    const payload = {
      from_city_id: fromCity,
      to_city_id: toCity,
      start_date_of_travel: startDate,
      end_date_of_travel: endDate,
    };

    axios
      .post(`${process.env.REACT_APP_API_URL}travelPlans`, payload, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      })
      .then((response) => {
        setTravelPlans(response.data.data || []);
      })
      .catch(() => console.error("Failed to submit the travel plan."))
      .finally(() => setLoading(false));
  };

  const handleBookingClick = (confirmation_code) => {
    dispatch(addConfirmationCode(confirmation_code));
    navigate("/");
  };

  const columns = [
    {
      field: "sNo",
      headerName: "S.No",
      width: 80,
      headerClassName: "custom-header",
    },
    {
      field: "confirmation_code",
      headerName: "Confirmation Code",
      headerClassName: "custom-header",
      flex: 1,
      renderCell: ({ row }) => (
        <Typography
          color="#4A51F8"
          sx={{ cursor: "pointer", textDecoration: "underline", p: 2 }}
          onClick={() => handleBookingClick(row.confirmation_code)}
        >
          {row.confirmation_code}
        </Typography>
      ),
    },
    {
      field: "start_date_of_travel",
      headerName: "Start Date",
      headerClassName: "custom-header",
      flex: 1,
      renderCell: ({ row }) =>
        new Date(row.start_date_of_travel).toLocaleDateString(),
    },
    {
      field: "end_date_of_travel",
      headerName: "End Date",
      headerClassName: "custom-header",
      flex: 1,
      renderCell: ({ row }) =>
        new Date(row.end_date_of_travel).toLocaleDateString(),
    },
    {
      field: "created_at",
      headerName: "Created At",
      headerClassName: "custom-header",
      flex: 1,
      renderCell: ({ row }) => new Date(row.created_at).toLocaleDateString(),
    },
  ];

  return (
    <Box
      sx={{ mt: 4, p: 3, bgcolor: "#f9f9f9", borderRadius: 3, boxShadow: 2 }}
    >
      {/* Travel Planner Form */}
      <Typography
        variant="h5"
        sx={{ fontWeight: "bold", mb: 2, color: "#4A51F8" }}
      >
        Check Bookings
      </Typography>
      <Box display="flex" flexWrap="wrap" gap={2} mb={3}>
        {/* From Section */}
        <Box sx={{ flex: 1, minWidth: 300 }}>
          <Typography variant="subtitle1">From</Typography>
          <Select
            fullWidth
            value={selectedFromCountry}
            onChange={(e) => {
              setSelectedFromCountry(e.target.value);
              setFromCity("");
            }}
            displayEmpty
          >
            <MenuItem value="">Select a country</MenuItem>
            {countries.map((country) => (
              <MenuItem key={country.country_id} value={country.country_id}>
                {country.country_name}
              </MenuItem>
            ))}
          </Select>

          <Select
            fullWidth
            value={fromCity}
            onChange={(e) => setFromCity(e.target.value)}
            disabled={!fromCities.length}
            displayEmpty
            sx={{ mt: 2 }}
          >
            <MenuItem value="">Select a city</MenuItem>
            {fromCities.map((city) => (
              <MenuItem key={city.city_id} value={city.city_id}>
                {city.city_name}
              </MenuItem>
            ))}
          </Select>

          <TextField
            fullWidth
            type="date"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            sx={{ mt: 2 }}
          />
        </Box>

        {/* To Section */}
        <Box sx={{ flex: 1, minWidth: 300 }}>
          <Typography variant="subtitle1">To</Typography>
          <Select
            fullWidth
            value={selectedToCountry}
            onChange={(e) => {
              setSelectedToCountry(e.target.value);
              setToCity("");
            }}
            displayEmpty
          >
            <MenuItem value="">Select a country</MenuItem>
            {countries.map((country) => (
              <MenuItem key={country.country_id} value={country.country_id}>
                {country.country_name}
              </MenuItem>
            ))}
          </Select>

          <Select
            fullWidth
            value={toCity}
            onChange={(e) => setToCity(e.target.value)}
            disabled={!toCities.length}
            displayEmpty
            sx={{ mt: 2 }}
          >
            <MenuItem value="">Select a city</MenuItem>
            {toCities.map((city) => (
              <MenuItem key={city.city_id} value={city.city_id}>
                {city.city_name}
              </MenuItem>
            ))}
          </Select>

          <TextField
            fullWidth
            type="date"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
            sx={{ mt: 2 }}
          />
        </Box>
      </Box>
      <Button
        variant="contained"
        onClick={handleSubmit}
        disabled={loading}
        sx={{
          backgroundColor: "#4A51F8",
          "&:hover": { backgroundColor: "#3a41d8" },
          p: 2,
          mb: 2,
        }}
      >
        {loading ? <CircularProgress size={24} /> : "Submit"}
      </Button>
      {/* Travel Plans Table */}
      <DataGrid
        rows={travelPlans.map((row, index) => ({ ...row, sNo: index + 1 }))}
        columns={columns}
        getRowId={(row) => row.confirmation_code}
        pageSize={pageSize}
        onPaginationModelChange={(params) => setPageSize(params)}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 5,
            },
          },
        }}
        pageSizeOptions={[5]}
        sx={{
          "& .custom-header": { backgroundColor: "#4A51F8", color: "white" },
          "& .MuiDataGrid-columnHeaderTitle": { color: "white" }, // Column text color
          "& .MuiDataGrid-sortIcon, & .MuiDataGrid-menuIconButton": {
            color: "#fff",
          }, // Sorting & Menu icon
          "& .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-columnHeader:focus-within":
            { outline: "none" },
        }}
      />
    </Box>
  );
};

export default RecentBooking;
